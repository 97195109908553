<template>
    <v-dialog
        v-model="dialog"
        max-width="600"
        persistent
    >
        <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{nombre}}
                </v-card-title>
                <v-card-text class="pt-2">
                    <!-- ---------------------------------------------------- -->
                    <v-text-field
                        class="pt-2"
                        hint="Anote observaciones antes de seleccionar"
                        :persistent-hint="true"
                        v-model="observaciones"
                        filled
                        rounded
                        clearable
                        dense
                        label="Observaciones"
                    ></v-text-field>

                    <table style="width:100%">
                        <tbody>
                            <tr>
                                <td width="40%" class="blue accent-1 text-center"><p class="caption text-justify ma-2 white--text">Sin dolor, eritema, hinchazón ni cordón palpable</p></td>
                                <td width="10%" class="text-center blue-grey lighten-2">
                                    <v-btn rounded small @click="agrega(0)">0</v-btn>
                                </td>
                                <td width="40%" class="green text-center"><p class="caption text-justify ma-2 white--text">NO signos de flebitis. OBSERVE punto de inserción</p></td>
                            </tr>
                            <tr>
                                <td width="40%" class="pink lighten-2 text-center"><p class="caption text-justify ma-2 white--text">Dolor sin eritema, hinchazón ni cordón palpable en la zona de punción</p></td>
                                <td width="10%" class="text-center blue-grey lighten-2">
                                    <v-btn rounded small @click="agrega(1)">1</v-btn>
                                </td>
                                <td width="40%" class="orange"><p class="caption text-justify ma-2 white--text">Posible signo de flebitis. OBSERVE punto de inserción</p></td>
                            </tr>
                            <tr>
                                <td width="40%" class="purple lighten-3 text-center"><p class="caption text-justify ma-2 white--text">Dolor con eritema y/o hinchazón sin cordón palpable en la zona de punción. Dolor, eritema, hinchazón, endurecimiento o cordón venoso palpable menor a 6cm por encima del sitio de inserción</p></td>
                                <td width="10%" class="text-center blue-grey lighten-2">
                                    <v-btn rounded small @click="agrega(2)">2</v-btn>
                                </td>
                                <td width="40%" class="red"><p class="caption text-justify ma-2 white--text">Inicio de flebitis. RETIRE el catéter</p></td>
                            </tr>
                            <tr>
                                <td width="40%" class="purple lighten-3 text-center"><p class="caption text-justify ma-2 white--text">Dolor, eritema, hinchazón, endurecimiento o cordón venoso palpable mayor a 6cm por encima del sitio de inserción y/o purulencia</p></td>
                                <td width="10%" class="text-center blue-grey lighten-2">
                                    <v-btn rounded small @click="agrega(3)">3</v-btn>
                                </td>
                                <td width="40%" class="red text-center"><p class="caption text-justify ma-2 white--text">Etapa media de flebitis. RETIRE el catéter y valore el tratamiento</p></td>
                            </tr>
                            <tr>
                                <td width="40%" class="purple lighten-3 text-center"><p class="caption text-justify ma-2 white--text">Trombosis venosa franca con todos los signos anteriores y dificultad o detención de la perfusión</p></td>
                                <td width="10%" class="text-center blue-grey lighten-2">
                                    <v-btn rounded small @click="agrega(4)">4</v-btn>
                                </td>
                                <td width="40%" class="red" ><p class="caption text-justify ma-2 white--text">Avanzado estado de flebitis. RETIRE el catéter y valore el tratamiento</p></td>
                            </tr>
                            <tr>
                                <td width="40%" class="purple lighten-3 text-center"><p class="caption text-justify ma-2 white--text">Sin dolor, eritema, hinchazón ni cordón palpable</p></td>
                                <td width="10%" class="text-center blue-grey lighten-2">
                                    <v-btn rounded small @click="agrega(5)">5</v-btn>
                                </td>
                                <td width="40%" class="red"><p class="caption text-justify ma-2 white--text">Tromboflebitis. RETIRE el catéter e inicie el tratamiento</p></td>
                            </tr>
                        </tbody>
                    </table>

                    

                    <!-- ---------------------------------------------------- -->
                </v-card-text>

                <v-card-actions>
                    <!--
                    <v-btn
                        color="success"
                        :loading="loading"
                        @click="agrega"
                    >
                        Agregar
                    </v-btn>
                    -->
                    <v-btn color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogDe:Boolean,
        turno:String,
        nombre:String,
        estaFecha: String,
        internamiento:Number,
    },

    data:()=>({
        loading:false,
        valor:0,
        observaciones:'',
    }),

    computed:{
        dialog:{
            get(){
                return this.dialogDe
            },
            set(val){
                this.$emit('update:dialogDe',val)
            },
        }
    },
    methods:{
        cancela(){
            this.dialog = false
        },
        async agrega(val){

            this.valor = val
            this.loading=true

            let datos = {
                fecha: this.estaFecha,
                turno: this.turno,
                escala: 'flebitis',
                valor: this.valor,
                internamiento:this.internamiento,
                observaciones: this.observaciones,
            }
            try{
                let dt = await this.$http({
                    url:'/enfermeria/guardaEscalas',
                    method: 'POST',
                    data: datos
                })
                if (dt.data.estado == true){
                    this.$emit('escalaSuccess')
                    this.observaciones = ''
                    this.valor = 0
                    this.loading = false
                    this.dialog = false
                } else {
                    console.log(dt.data)
                    this.loading = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }




            
            // this.$emit('escalaSuccess')
            // console.log ('Actualizando FLEBITIS en en turno '+this.estaFecha+'/'+this.turno+' con los valores '+this.valor)
            // this.dialog = false
            
        },
    },
}
</script>

<style>

</style>